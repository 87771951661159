import React from 'react';
// import AboutSection from './sections/AboutSection';
import HeroSection from './sections/HeroSection';
// import OurTeamSection from './sections/OurTeamSection';
import ContactSection from './sections/ContactSection';
// import SocialMediaSection from '../../components/SocialMediaSection';
import { ScrollToTop } from '../../components/ScrollToTop';

const ContactPage = () => {
  return (
    <main className='home-page'>
      <ScrollToTop />
      <HeroSection />
      {/* <AboutSection /> */}
      {/* <OurTeamSection /> */}
      <ContactSection />
    </main>
  );
};

export default ContactPage;
