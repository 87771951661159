import React from 'react';
import { ScrollToTop } from '../../components/ScrollToTop';

const NotFoundPage = () => {
  return (
    <main className='home-page'>
      <ScrollToTop />
      <section className='vh-100 py-0'>
        <img
          className='d-block h-100 w-100 object-cover'
          src='/assets/images/ErrorPage404.jpg'
          alt='...'
        />
      </section>
    </main>
  );
};

export default NotFoundPage;
