import React from 'react';
import PartnerHero from './components/PartnerHero';
import Banner from './components/Banner';
import { PenTool, Shield, Key, Globe, Plus } from 'react-feather';
import SubscribeSection from '../../pages/our-services/section/SubscribeSection';
import { Divider } from 'antd';

const BigCommerce = () => {
    return (
        <>
            <PartnerHero partner='BigCommerce' />
            <div className='container ms-partnership'>
                <div className='row'>
                    <div className='ms-partnership'>
                        <img id='partnership-img' className='inline-block col-md-2 col-12' src='/assets/images/Partner-Certified.png' alt='BigCommerce  Certified Partner' />
                        <Plus />
                        <img id='partnership-img' className='inline-block col-md-2 col-12' src='/assets/images/logo1.png' alt='Menara Solutions' />
                    </div>
                    <p>
                        BigCommerce is a leading ecommerce platform that allows online stores to scale and sell more. It is a SaaS solution that offers a wide range of features to help businesses build, run, and scale their online stores. BigCommerce is a fully hosted platform, which means that it takes care of all the technical aspects of running an online store, including web hosting, security, and performance.
                    </p>
                </div>
            </div>
            {/* <div className='container'>
                <Divider />
            </div> */}
            <div className='partner-sections'>
                <Banner partner='BigCommerce' />
                <Divider />
                <section className='service-card p-5'>
                    <h4><Key color='#329a8e' display='block' />Key Features</h4>
                    <ul className='px-0'>
                        <li>Product catalog management</li>
                        <li>Secure payment gateways</li>
                        <li>Shipping and fulfillment integrations</li>
                        <li>Abandoned cart recovery</li>
                        <li>Multi-channel selling (e.g., eBay, Amazon)</li>
                        <li>Product reviews and ratings</li>
                        <li>Discounts and promotions</li>
                        <li>Customer segmentation and targeting</li>
                    </ul>
                </section>

                <section className='service-card p-5'>
                    <h4><PenTool color='#329a8e' display='block' />Design and Customization</h4>
                    <ul className='px-0'>
                        <li>Responsive themes</li>
                        <li>Drag-and-drop website builder</li>
                        <li>Customizable templates</li>
                        <li>Visual merchandising</li>
                        <li>Advanced CSS and HTML editing</li>
                        <li>Mobile optimization</li>
                        <li>Brand and logo customization</li>
                        <li>Localization and internationalization support</li>
                    </ul>
                </section>

                <section className='service-card p-5'>
                    <h4><Globe color='#329a8e' display='block' />Marketing and SEO</h4>
                    <ul className='px-0'>
                        <li>Search engine optimization (SEO) tools</li>
                        <li>Built-in blog functionality</li>
                        <li>Social media integration</li>
                        <li>Email marketing integration</li>
                        <li>Product recommendations</li>
                        <li>Product comparison and wishlists</li>
                        <li>Customer analytics and reporting</li>
                        <li>Marketing automation</li>
                    </ul>
                </section>

                <section className='service-card p-5'>
                    <h4><Shield color='#329a8e' display='block' />Security and Support</h4>
                    <ul className='px-0'>
                        <li>PCI compliance</li>
                        <li>Secure checkout process</li>
                        <li>SSL certificate</li>
                        <li>Regular security updates</li>
                        <li>24/7 technical support</li>
                        <li>Developer documentation and resources</li>
                        <li>Community forums</li>
                        <li>Training and onboarding programs</li>
                    </ul>
                </section>
            </div>
            <SubscribeSection />
        </>
    );
};

export default BigCommerce;
