import React from 'react';
// import { Link } from 'react-router-dom';
import BgPattern from '../../../components/pattern/BgPattern';

const CaseHero = (project) => {
    console.log(project);
  return (
    <section className='position-relative container-xxl px-0' id='hero'>
      <BgPattern />
      <div className='px-0 mx-auto col-12 col-md-9 col-lg-8 col-xl-7 text-center mb-5'>
        <div className='icon-size-medium me-3'>
          <img
            className='d-block h-100 w-100'
            src={project.project.logo}
            alt='Client Logo'
            />
        </div>
        <div className='display-6 fw-bold font-volkhov text-secondary text-dark'>
          {project.project.client}
        </div>
      </div>
    </section>
  );
};

export default CaseHero;
