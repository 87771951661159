import React from 'react';

const OurExpertiseSection = () => {
  const list = [
    {
      iconUrl: '/assets/images/Tech Support-18@2x.png',
      title: 'Full-service and in-house',
      text: `Discover the convenience of our full-service and in-house solutions. With our expert team, we provide a comprehensive range of services tailored to your needs. From design to execution, we take care of every aspect of your project. Trust us to deliver exceptional results while you focus on what matters most to you.`,
      isLine: true,
    },
    {
      iconUrl: '/assets/images/Full service-16@2x.png',
      title: 'Tech Lead Support',
      text: `Benefit from the expertise of our dedicated tech lead support team. Our experienced professionals are here to guide you through complex technical challenges and provide strategic advice for your projects. From troubleshooting to decision-making, rely on our tech lead support to streamline your processes and achieve optimal results.`,
      isLine: false,
    },
  ];

  return (
    <section className='bg-white'>
      <div className='container'>
        <div className='row gy-4 position-relative'>
          <div className='col col-lg-6 col-xxl-6'>
            <div className='px-0 col-12 col-xl-11 col-xxl-10'>
              <div className='mb-4 display-5 fw-bold font-volkhov text-dark text-capitalize'>
                Your Vision. Our Expertise.
              </div>
              <p className='mb-3 lh-lg text-secondary text-opacity-75'>
              At our core, we believe in the power of collaboration and bringing your vision to life. 
              We combine your unique ideas with our expertise to deliver outstanding results. 
              Our team of skilled professionals is passionate about understanding your goals and objectives, 
              ensuring that every project reflects your vision. With our extensive experience and 
              industry knowledge, we provide valuable insights and innovative solutions that elevate 
              your business to new heights. Trust us to be your reliable partner, working hand in hand 
              to turn your vision into reality. Together, we can achieve greatness and exceed your expectations.
              </p>
              {/* <div>
                <img
                  className='d-block h-100 w-100'
                  src='/assets/images/e-image-0.png'
                  alt='...'
                />
              </div> */}
            </div>
          </div>
          <div className='col-12 col-lg-6 col-xxl-6 order-first order-lg-last'>
            <div>
              <ul className='nav flex-column gap-5'>
                {list.map((item) => (
                  <li>
                    <div className='d-flex mb-4'>
                      <div className='icon-size me-3'>
                        <img
                          className='d-block h-100 w-100'
                          src={`${item.iconUrl}`}
                          alt='..'
                        />
                      </div>
                      <div>
                        <h6 className='mb-1 fw-bold text-secondary text-capitalize'>
                          {item.title}
                        </h6>
                        <p className='mb-0 lh-lg text-secondary text-opacity-75 small'>
                          {item.text}
                        </p>
                      </div>
                    </div>
                    {item.isLine && <hr className='bg-gray' />}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurExpertiseSection;
