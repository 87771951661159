import React from 'react';
import Map from '../../about/sections/Map';

const ContactSection = () => {
  

  return (
    <section>
      <div className='container'>
        <div className='mb-4'>
          <div className='mb-2 display-5 fw-bold font-volkhov text-dark text-capitalize'>
            Our Headquarters
          </div>
          <p className='mb-0 fw-bold text-secondary'>
            Let's build amazing things together. 
          </p>
        </div>
        <div className='row align-items-center gy-4 position-relative'>
          <div className='col col-lg-6 col-xl-4'>
            <div className='px-0 col-12 col-xl-9 col-xxl-7'>
              <ul className='nav flex-column gap-4'>
                <li>
                  <div>
                    <div className='icon-size-small mb-2'>
                      <img
                        className='d-block h-100 w-100 object-contain'
                        src={`/assets/images/location.png`}
                        alt='..'
                      />
                    </div>
                    <div>
                      <p className='mb-0 text-dark'>
                        Atlanta, GA 30339
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div>
                    <div className='icon-size-small mb-2'>
                      <img
                        className='d-block h-100 w-100 object-contain'
                        src={`/assets/images/mail.png`}
                        alt='..'
                      />
                    </div>
                    <div>
                      <a
                        href='mailto:contact@menarasolutions.com'
                        className='mb-0 text-dark'
                      >
                        contact@menarasolutions.com
                      </a>
                    </div>
                  </div>
                </li>
                <li>
                  <div>
                    <div className='icon-size-small mb-2'>
                      <img
                        className='d-block h-100 w-100 object-contain'
                        src={`/assets/images/phone.png`}
                        alt='..'
                      />
                    </div>
                    <div>
                      <a href='tel:+48533600136' className='mb-0 text-dark'>
                        +1 404 910 6051
                      </a>
                    </div>
                  </div>
                </li>
                {/* <li>
                  <div>
                    <div className='icon-size-small mb-2'>
                      <img
                        className='d-block h-100 w-100 object-contain'
                        src={`/assets/images/chat.png`}
                        alt='..'
                      />
                    </div>
                    <div>
                      <p className='mb-0 text-dark'>
                        Write to our team:
                      </p>
                      <a href='mailto:marketing@menara.com' className='mb-0'>
                        marketing@menara.com
                      </a>
                    </div>
                  </div>
                </li> */}
              </ul>
            </div>
          </div>
          <div className='col-12 col-lg-6 col-xl-8'>
            <Map />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
