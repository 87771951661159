import React from 'react';
// import { Swiper, SwiperSlide } from 'swiper/react';
import BgPattern from '../../../components/pattern/BgPattern';
import 'swiper/css/free-mode';
// import { FreeMode } from 'swiper';
// import AboutVIdeoCard from '../../../components/cards/AboutVIdeoCard';

const HeroSection = () => {
  // const list = [{}, {}, {}, {}];

  return (
    <section className='position-relative container-xxl px-0'>
      <BgPattern />

      <div className='position-relative mb-5 py-5 meet-menara'>
        <div>
          <div className='row mx-0'>
            <div className='col px-0'>
              <div className='position-relative about-content'>
                <div>
                  <div className='mb-4 display-6 fw-bold font-volkhov text-secondary'>
                    Meet Menara Solutions!
                  </div>
                  <div className='bg-white shadow rounded rounded-3 p-3 small'>
                    <div className='mb-2 fw-bold text-secondary'>
                      This is how we do it.
                    </div>
                    <div className='text-black-50'>
                      Our team is dedicated to bringing your ideas to life by combining creativity
                      and innovation with the latest technology. As experts in software development,
                      we offer a range of services including custom e-commerce websites, web applications, mobile app
                      development, and software consulting to meet your unique needs and help you thrive.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 col-md-7 col-xl-9 px-0'>
              <div className='height-size-1 h-100-all'>
                <object
                  className='d-block h-100 w-100'
                  data='/assets/images/team.svg'
                  type='image/svg+xml'
                  aria-label='Menara Solutions Team'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
