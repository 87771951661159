import React from 'react';
// import SocialMediaSection from '../../components/SocialMediaSection';
import HeroSection from './section/HeroSection';
import SubscribeSection from './section/SubscribeSection';
import OurExpertiseSection from './section/OurExpertiseSection';
import OurSkillsSection from './section/OurSkillsSection';
// import ServicesSection from './section/ServicesSection';
// import TechnologiesSection from './section/TechnologiesSection';
// import TeamYouNeedSection from './section/TeamYouNeedSection';
// import OurBlogSection from './section/OurBlogSection';
import { ScrollToTop } from '../../components/ScrollToTop';

const ServicesPage = () => {
  return (
    <main className='home-page'>
      <ScrollToTop />
      <HeroSection />
      <OurSkillsSection />
      <br />
      {/* <TechnologiesSection /> */}
      {/* <ServicesSection /> */}
      <OurExpertiseSection />
      <SubscribeSection />
      {/* <TeamYouNeedSection /> */}
      {/* <OurBlogSection /> */}
    </main>
  );
};

export default ServicesPage;
