import React from 'react';
// import { Link } from 'react-router-dom';

const AboutSection = () => {
  return (
    <section className='py-0 bg-white mt-5'>
      <div className='container-xxl px-0'>
        <div className=''>
          <div className='row row-cols-1 row-cols-lg-2 mx-0'>
            <div className='px-0 mt-4 mt-lg-0 position-relative'>
              <span className='position-absolute w-100'>
                <img
                  className='d-block h-100 w-100'
                  src='/assets/images/bg-wave.png'
                  alt='..'
                />
              </span>
              <div className='px-3 py-5 d-flex align-items-center position-relative'>
                <div className='col-12 col-sm-10 col-md-9 col-lg-10 col-xl-8 col-xxl-7 px-0 mx-auto'>
                  <ul className='nav flex-column gap-4'>
                    <li>
                      <div className='display-6 fw-bold font-volkhov text-secondary'>
                        How we work
                      </div>
                    </li>

                    <li>
                      <p className='mb-0 text-gray'>
                        We are dedicated to delivering high-quality, user-friendly 
                        solutions that are tailored to meet your unique needs. We understand 
                        that every business is different, and we take the time to listen and 
                        understand your specific challenges and goals. With our deep 
                        knowledge and expertise, we're able to provide the guidance and 
                        solutions you need to achieve success.
                      </p>
                    </li>

                    {/* <li>
                      <div>
                        <Link to className='d-inline-flex align-items-center'>
                          <span className='bg-primary p-2 rounded-circle me-2'>
                            <img
                              className='d-block h-100 w-100'
                              src='/assets/images/chat-2.png'
                              alt='...'
                            />
                          </span>
                          <span className='fw-bold font-volkhov text-secondary'>
                            Read more
                          </span>
                        </Link>
                      </div>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className='px-0 order-first order-lg-last'>
              <div className='height-size-1'>
                <img
                  className='d-block h-100 w-100 object-cover'
                  src='/assets/images/about-image-6.png'
                  alt='..'
                />
              </div>
            </div>
          </div>
        </div>
        {/* === */}
        <div className=''>
          <div className='row row-cols-1 row-cols-lg-2 mx-0'>
            <div className='px-0'>
              <div className='height-size-1'>
                <img
                  className='d-block h-100 w-100 object-cover'
                  src='/assets/images/about-image-5.png'
                  alt='..'
                />
              </div>
            </div>
            <div className='px-0 mt-4 mt-lg-0 position-relative bg-white'>
              <span className='position-absolute w-100'>
                <img
                  className='d-block h-100 w-100'
                  src='/assets/images/bg-wave.png'
                  alt='..'
                />
              </span>
              <div className='px-3 py-5 d-flex align-items-center position-relative'>
                <div className='col-12 col-sm-10 col-md-9 col-lg-10 col-xl-8 col-xxl-7 px-0 mx-auto'>
                  <ul className='nav flex-column gap-4'>
                    <li>
                      <div className='display-6 fw-bold font-volkhov text-secondary'>
                        The team
                      </div>
                    </li>

                    <li>
                      <p className='mb-0 text-gray'>
                        We believe that collaboration is key to success, and that's why we work 
                        closely with our clients to ensure that we're on the same page every 
                        step of the way. Our team is made up of skilled developers, designers, 
                        project managers, and quality assurance specialists who work together 
                        to ensure that your project is delivered on time and on budget.
                      </p>
                    </li>

                    {/* <li>
                      <div>
                        <Link to className='d-inline-flex align-items-center'>
                          <span className='bg-primary p-2 rounded-circle me-2'>
                            <img
                              className='d-block h-100 w-100'
                              src='/assets/images/chat-2.png'
                              alt='...'
                            />
                          </span>
                          <span className='fw-bold font-volkhov text-secondary'>
                            Read more
                          </span>
                        </Link>
                      </div>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
