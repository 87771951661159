import React, { useState } from 'react';
import { Form, Alert } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import BgPattern from '../../../components/pattern/BgPattern';

const HeroSection = () => {
  const [validated, setValidated] = React.useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const servicesList = [
    { name: 'Development' },
    { name: 'Design' },
    { name: 'Development & Design' },
    { name: 'Other' },
  ];

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const service = form.elements["services"].value;
      const name = form.elements["name"].value;
      const email = form.elements["email"].value;
      const phone = form.elements["phone"].value;
      const message = form.elements["message"].value;

      fetch('https://menara-mailer-c9553dd9f56c.herokuapp.com/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: name,
          email: email,
          phone: phone,
          service: service,
          message: message,
        }),
      })
        .then((response) => {
          if (response.ok) {
            setShowConfirmation(true);
          } else {
            setShowError(true);
          }
          return response.json();
        })
        .then((data) => {
          if (data && data.error) {
            setErrorMessage(data.error);
          }
        })
        .catch((error) => console.error('Error:', error));
    }
    setValidated(true);
  };



  return (
    <section className='position-relative container-xxl px-0' id='hero'>
      <BgPattern />
      <span className='position-absolute pos-y-50'>
        <img
          className='d-block h-100 w-100'
          src='/assets/images/bg-arrow.png'
          alt='...'
        />
      </span>
      <div className='container py-5 position-relative'>
        <div className='row justify-content-between gy-4'>
          <div className='col-12 col-md-4 col-xl-5'>
            <div className='pt-5 px-0 col-12 col-xl-10 position-sticky top-40'>
              <ul className='nav flex-column gap-5'>
                <li>
                  <div className='display-6 fw-bold font-volkhov text-secondary'>
                    Contact us
                  </div>
                </li>
                <li>
                  <h5 className='mb-2 fw-500 text-secondary'>
                    Share your project details with us.
                  </h5>
                  <h5 className='mb-0 fw-normal text-secondary text-opacity-75 lh-lg'>
                    Expect a response within 24 hours as we schedule a discussion to delve deeper into your project requirements.
                  </h5>
                </li>
              </ul>
            </div>
          </div>
          <div className='col'>
            <div className='bg-white bg-opacity-75 shadow rounded-xl p-4'>
              <Form
                className='position-relative'
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
              >
                <ul className='nav flex-column gap-4'>
                  <li>
                    <input
                      type='text'
                      className='form-control bg-white rounded-lg border-0 shadow p-3'
                      placeholder='Your name'
                      name='name'
                      required
                    />
                  </li>
                  <li>
                    <input
                      type='email'
                      className='form-control bg-white rounded-lg border-0 shadow p-3'
                      placeholder='Your email'
                      name='email'
                      required
                    />
                  </li>
                  <li>
                    <input
                      type='text'
                      className='form-control bg-white rounded-lg border-0 shadow p-3'
                      placeholder='Your phone number (optional)'
                      name='phone'
                    />
                  </li>
                  <li>
                    <div className='mt-4'>
                      <h5 className='mb-4 fw-500 text-secondary'>
                        What services are you looking for?
                      </h5>
                      <ul className='nav flex-column gap-3 radio-container'>
                        {servicesList.map((item) => (
                          <li>
                            <div className='form-check'>
                              <input
                                className='form-check-input'
                                type='radio'
                                name='services'
                                required
                                id={`services${item.name}`}
                                value={item.name}
                              />
                              <label
                                className='form-check-label'
                                for={`services${item.name}`}
                              >
                                {item.name}
                              </label>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </li>
                  <li>
                    <textarea
                      rows='4'
                      className='form-control bg-white rounded-lg border-0 shadow p-3'
                      placeholder='Massage (optional)'
                      name='message'
                    />
                  </li>
                  {/* <li>
                    <div>
                      <div className='form-check'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          name='except'
                          id={`except`}
                          required
                        />
                        <label className='form-check-label' for={`except`}>
                          Please send me the presentation about cooperating with
                          Menara Solutions
                        </label>
                      </div>
                      <div className='mt-2 text-center form-check p-0'>
                        <label
                          for={`except`}
                          className='mb-0 text-dark text-opacity-50'
                        >
                          <small>
                            Menara Solutions needs the contact information you provide
                            to us to contact you about our products and
                            services. You may unsubscribe from these
                            communications at any time. For information on how
                            to unsubscribe, as well as our privacy practices and
                            commitment to protecting your privacy, please review
                            our{' '}
                            <Link to className='text-decoration-underline'>
                              Privacy Policy
                            </Link>
                            .
                          </small>
                        </label>
                      </div>
                    </div>
                  </li> */}
                  <li>
                    {showConfirmation && (
                      <Alert variant='success'>
                        Your email has been sent successfully!
                      </Alert>
                    )}
                    {showError && (
                      <Alert variant='danger'>
                        Error occurred: {errorMessage}
                      </Alert>
                    )}
                  </li>
                </ul>
                <div className='mt-4' />
                <div className='position-absolute w-100 text-center'>
                  <button
                    type='submit'
                    className='btn btn-primary rounded-pill'
                  >
                    Submit Estimate Request
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
