import React from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';

const ClientsSection = () => {
  // const list = [{}, {}, {}, {}, {}, {}];

  return (
    <section className='clients-section' id='clients'>
      <div className='container'>
        <div>
          <h3 className='mb-0 text-capitalize font-volkhov text-center'>
            Partnered with the Best
          </h3>

          <Swiper
            breakpoints={{
              '@0.00': {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              '@0.75': {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              '@1.00': {
                slidesPerView: 4,
                spaceBetween: 40,
              },
              '@1.50': {
                slidesPerView: 4,
                spaceBetween: 50,
              },
            }}
            className='partner-swiper'
          >
            {/* {list.map((item, i) => ( */}
            <SwiperSlide key="bigcommerce" className="partners">
              <div className="slide-contents">
                <Link
                  to='/bigcommerce'
                >
                  <img
                    className='d-block h-100 w-100'
                    src={`/assets/images/Partner-Certified.png`}
                    alt='BigCommerce Partner Logo'
                  />
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide key="shopify" className="partners">
              <div className="slide-contents">
                <Link
                  to='/shopify'
                >
                  <img
                    className='d-block h-100 w-100'
                    src={`/assets/images/ShopifyPartners_Primary.svg`}
                    alt='Shopify Partner Logo'
                  />
                </Link>
              </div>
            </SwiperSlide>
            {/* ))} */}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default ClientsSection;
