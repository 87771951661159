import React from 'react';
import { ScrollToTop } from '../../components/ScrollToTop';
// import SocialMediaSection from '../../components/SocialMediaSection';
// import BackendSection from './sections/BackendSection';
import FeaturedSection from './sections/FeaturedSection';
import HeroSection from './sections/HeroSection';

const ProjectsPage = () => {
  return (
    <main className='home-page projects-page'>
      <ScrollToTop />
      <HeroSection />
      <FeaturedSection />
      {/* <BackendSection /> */}
    </main>
  );
};

export default ProjectsPage;
