import React from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import { Descriptions, Collapse, Space, theme } from 'antd';

const CaseStudy = ({ project }) => {
  const { description, duration, teamSize, technologiesUsed, challenges, solution, results } = project.project;
  const { token } = theme.useToken();
  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };

  return (
    <div className="project-page home-page">
      <div className="container">
        <Descriptions>
          <Descriptions.Item label="Duration">{duration}</Descriptions.Item>
          <Descriptions.Item label="Team Size">{teamSize}</Descriptions.Item>
        </Descriptions>
        <Space direction="vertical">
          <Collapse
            bordered={false}
            collapsible="header"
            defaultActiveKey={['1', '2', '3', '4', '5']}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            items={[
              {
                key: '1',
                label: 'Description',
                children: <p>{description}</p>,
                style: panelStyle,
              },
              {
                key: '2',
                label: 'Technologies Used',
                children: <Descriptions bordered>
                  {technologiesUsed.map((technology) => (
                    <Descriptions.Item>{technology}</Descriptions.Item>
                  ))}

                </Descriptions>,
                style: panelStyle,
              },
              {
                key: '3',
                label: 'Challenges',
                children: <p>{challenges}</p>,
                style: panelStyle,
              },
              {
                key: '4',
                label: 'Solution',
                children: <p>{solution}</p>,
                style: panelStyle,
              },
              {
                key: '5',
                label: 'Results',
                children: <p>{results}</p>,
                style: panelStyle,
              },
            ]}
            style={{
              background: token.colorBgContainer
            }}
          />
        </Space>
      </div>
      <br />
    </div>
  );
};

export default CaseStudy;
