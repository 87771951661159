import React from 'react';

const BgPattern = () => {
  return (
    <>
      <span className='position-absolute top-0'>
        <img
          className='d-block h-100 w-100'
          src='/assets/images/bg-left.png'
          alt='...'
        />
      </span>
      <span className='position-absolute bottom-0 end-0'>
        <img
          className='d-block h-100 w-100'
          src='/assets/images/bg-right.png'
          alt='...'
        />
      </span>
    </>
  );
};

export default BgPattern;
