import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

const NavbarPrimary = () => {
  const [show, setShow] = useState(false);
  const [navbar, setNavbar] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const changeBackground = () => {
    if (window.scrollY >= 180) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener('scroll', changeBackground);

  const links = [
    { name: 'Services', link: '/services' },
    { name: 'Projects', link: '/projects' },
    // { name: 'Blog', link: '/blog' },
    { name: 'Careers', link: '/careers' },
    { name: 'about', link: '/about' },
  ];

  return (
    <>
      {/* Desktop */}
      <header className={`fixed-top ${navbar && 'bg-white shadow'}`}>
        <nav className='py-2'>
          <div className='container'>
            <div className='d-flex align-items-center'>
              <div className='me-auto'>
                <Link to='/' className='d-block logo'>
                  <img
                    className='d-block h-100 w-100 object-contain'
                    src='/assets/images/logo1.png'
                    alt='logo'
                  />
                </Link>
              </div>
              <div className='nav-links me-5'>
                <ul className='nav align-items-center gap-5'>
                  {links.map((item) => (
                    <li className='d-none d-xl-block'>
                      <NavLink
                        to={item.link}
                        className={`link d-block text-capitalize`}
                      >
                        {item.name}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
              <div className='nav-links'>
                <ul className='nav align-items-center gap-4'>
                  <li className='d-none d-xl-block'>
                    <Link
                      to='/contact'
                      className='btn btn-primary rounded-pill'
                    >
                      Contact Us
                    </Link>
                  </li>

                  <li>
                    <button
                      href
                      type='button'
                      onClick={handleShow}
                      className='btn p-0 d-xl-none icon-size-1 border shadow-sm'
                    >
                      <img src='/assets/images/bars-line.png' alt='...' />
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>

      {/* Mobile */}

      <aside className={`sidebar ${show ? 'show' : 'hide'}`}>
        <div className='d-flex h-100'>
          <div className='px-0 col-9 col-md-5 col-lg-4'>
            <div className='offcanvas-header bg-white w-100'>
              <div className='d-flex align-items-center w-100 justify-content-between'>
                <a href='/#home' className='logo'>
                  <img
                    className='h-100 w-100 d-block object-contain'
                    src='/assets/images/logo1.png'
                    alt='logo'
                  />
                </a>
                <button
                  type='button'
                  onClick={handleClose}
                  className='btn-close'
                />
              </div>
            </div>
            <div className='offcanvas-body h-100 bg-white'>
              <div className='nav-links'>
                <ul className='nav flex-column gap-3 ps-2'>
                  {links.map((item) => (
                    <li>
                      <NavLink
                        onClick={handleClose}
                        to={item.link}
                        className={`link text-capitalize d-inline-block`}
                      >
                        {item.name}
                      </NavLink>
                    </li>
                  ))}
                  <li>
                    <Link
                      onClick={handleClose}
                      to='/contact'
                      className='btn btn-primary rounded-pill'
                    >
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            className='flex-fill bg-white bg-opacity-50'
            onClick={handleClose}
          />
        </div>
      </aside>
    </>
  );
};

export default NavbarPrimary;
