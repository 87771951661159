import React from 'react';
import { Link } from 'react-router-dom';
import BgPattern from '../../../components/pattern/BgPattern';

const HomeSection = () => {
  return (
    <section className='position-relative container-xxl px-0' id='hero'>
      <BgPattern />
      <div className='container pb-5 position-relative'>
        <div className='row align-items-center justify-content-between gy-4'>
          <div className='col-12 col-md-8 col-xl-6'>
            <div className='pt-5'>
              <ul className='nav flex-column gap-4'>
                <li>
                  <div className='display-5 fw-bold font-volkhov text-secondary text-capitalize'>
                    Building innovative software{' '}
                    <span className='font-lexend text-white text-shadow-black'>
                      solutions
                    </span>{' '}
                    {/* Leaders */}
                  </div>
                </li>
                <li>
                  <p className='mb-0 text-secondary text-opacity-75'>
                    Web and mobile application development
                  </p>
                </li>
                <li>
                  <div>
                    <Link
                      to='/contact'
                      className='btn btn-primary rounded-pill'
                    >
                      Get Started
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className='col-12 col-md-4 col-xl-5 order-first order-md-last'>
            <div>
              <object
                className='d-block h-100 w-100'
                data='/assets/images/management-process.svg'
                type='image/svg+xml'
                aria-label='icon'
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeSection;
