import React, { useEffect } from 'react';
import NavbarPrimary from './components/navbars/NavbarPrimary';
import FooterPrimary from './components/footers/FooterPrimary';
import Paths from './Paths';
import { useLocation } from 'react-router-dom';

function App({ onPageView }) {
  const location = useLocation();

  useEffect(() => {
    onPageView(location);
  }, [location, onPageView]);

  return (
    <main>
      <NavbarPrimary />
      <div>
        <Paths />
      </div>
      <FooterPrimary />
    </main>
  );
}

export default App;
