import React from 'react';
// import { Link } from 'react-router-dom';
import BgPattern from '../../../components/pattern/BgPattern';

const HeroSection = () => {
  return (
    <section className='position-relative container-xxl px-0' id='hero'>
      <BgPattern />

      {/* <div className='container my-5 py-5 position-relative'>
        <div className='px-0 mx-auto col-12 col-md-11 col-lg-10 col-xl-9 text-center'>
          <ul className='nav flex-column gap-5'>
            <li>
              <div className='display-6 fw-bold font-volkhov text-secondary'>
                Our team has worked on over{' '}
                <span className='text-line'>360 projects.</span> Here’s a
                sample.
              </div>
            </li>
            <li>
              <Link to className='d-inline-flex align-items-center'>
                <span className='bg-primary p-2 rounded-circle me-2'>
                  <img
                    className='d-block h-100 w-100'
                    src='/assets/images/chat-2.png'
                    alt='...'
                  />
                </span>
                <span>See Projects</span>
              </Link>
            </li>
          </ul>
        </div>
      </div> */}
      <div className='px-0 mx-auto col-12 col-md-9 col-lg-8 col-xl-7 text-center mb-5'>
        <div className='icon-size-medium me-3'>
          <object
            className='d-block h-100 w-100'
            data='/assets/images/web-development.svg'
            type='image/svg+xml'
            aria-label='icon'
          />
        </div>
        <div className='display-6 fw-bold font-volkhov text-secondary text-dark'>
          Featured Projects
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
