import React from 'react';
import ProjectCard from '../../../components/cards/ProjectCard';

const FeaturedSection = () => {
  const list = [
    {
      id: 1,
      title: 'PakMor LLC',
      text: 'Auto Parts Manufacturer and Distributor',
      imageUrl:
        '/assets/images/smartmockups_ljnf90kx.jpg',
      isActive: true,
      projectUrl: '/projects/pakmor-llc',
    },
  ];

  return (
    <section className='mb-5'>
      <div className='container position-relative'>
        <div>
          <ul className='nav row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 gy-4'>
            {list.map((item) => (
              <li className='d-flex flex-column'>
                <ProjectCard item={item} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default FeaturedSection;
