import React from 'react';
import { ScrollToTop } from '../../components/ScrollToTop';
// import SocialMediaSection from '../../components/SocialMediaSection';
import AboutSection from './sections/AboutSection';
// import ContactSection from './sections/ContactSection';
import HeroSection from './sections/HeroSection';
// import OpeningsSection from './sections/OpeningsSection';
// import OurTeamSection from './sections/OurTeamSection';
import Map from './sections/Map';

const AboutPage = () => {
  return (
    <main className='about-page'>
      <ScrollToTop />
      <HeroSection />
      <AboutSection />
      <br />
      {/* <OurTeamSection /> */}
      {/* <OpeningsSection /> */}
      {/* <ContactSection /> */}
      <Map />
    </main>
  );
};

export default AboutPage;
