import React from 'react';
import { ScrollToTop } from '../../../components/ScrollToTop';
import CaseHero from '../template/CaseHero';
import CaseStudy from '../cases/CaseStudy';

const ProjectTemplate = (project) => {
    return (
        <main className='home-page projects-page'>
            <ScrollToTop />
            <CaseHero project={project.project} />
            <br />
            <CaseStudy project={project} />
        </main>
    );
};

export default ProjectTemplate;
