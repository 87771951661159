import React from 'react';
import { Link } from 'react-router-dom';
import BgPattern from '../../../components/pattern/BgPattern';

const HeroSection = () => {
  return (
    <section className='position-relative container-xxl px-0' id='hero'>
      <BgPattern />

      <div className='container my-5 py-5 position-relative'>
        <div className='px-0 mx-auto col-12 col-md-11 col-lg-10 col-xl-9 text-center'>
          <ul className='nav flex-column gap-5'>
            <li>
              <div className='display-6 fw-bold font-volkhov text-secondary'>
                Unlocking Digital Potential: Explore Our Expert{' '}
                <span className='text-line-2'>Development and Design</span> Services
              </div>
            </li>
            <li>
              <Link className='btn btn-primary rounded-pill' to='/contact'>
                Get an estimate
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
