import React from 'react';
// import { Link } from 'react-router-dom';

const ServiceCard = ({ item }) => {
  return (
    <div
      className={`service-card p-4 text-center d-flex flex-column h-100 ${
        item.isActive && 'active'
      }`}
    >
      <div className='mb-1 icon-size-medium-large mx-auto'>
        <img
          className='d-block h-100 w-100'
          src={`${item.imageUrl}`}
          alt='...'
        />
      </div>
      <h4 id='card-title-lh' className='mb-2 fw-500 lh-base text-uppercase'>
        {item.titleOne} <br /> {item.titleTwo}
      </h4>
      <p className='mb-4 text-black-50'>{item.text}</p>
      {/* <div className='mt-auto'>
        <Link
          to='/services'
          className='icon-size-1 btn-light text-secondary rounded-circle d-flex align-items-center justify-content-center mx-auto'
        >
          <i className='fa fa-arrow-right small' />
        </Link>
      </div> */}
    </div>
  );
};

export default ServiceCard;
