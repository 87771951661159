import React from 'react';
import ProjectsSection from './sections/ProjectsSection';
import HomeSection from './sections/HomeSection';
import ClientsSection from './sections/ClientsSection';
import ServiceSection from './sections/ServiceSection';
// import OurSuccessSection from './sections/OurSuccessSection';
import OurTeamSection from './sections/OurTeamSection';
import AboutYourProject from './sections/AboutYourProject';
import { ScrollToTop } from '../../components/ScrollToTop';
import SubscribeSection from '../our-services/section/SubscribeSection';

const HomePage = () => {
  return (
    <main className='home-page'>
      <ScrollToTop />
      <HomeSection />
      <ClientsSection />
      <ServiceSection />
      <br />
      <ProjectsSection />
      <OurTeamSection />
      <AboutYourProject />
      <SubscribeSection />
    </main>
  );
};

export default HomePage;
