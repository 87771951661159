import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import 'mapbox-gl/dist/mapbox-gl.css';
import App from './App';
import './styles/main.scss';
import ReactGA from 'react-ga';
import 'swiper/css';

ReactGA.initialize('G-QGX19NV53N'); 

const sendPageView = (location) => {
  ReactGA.pageview(location.pathname);
};

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App onPageView={sendPageView} />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
