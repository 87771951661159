import React from "react";
// import { Link } from "react-router-dom";

const Banner = (partner) => {
    let partnerName = partner.partner;
    let highlights = [];

    if (partnerName === "Shopify") {
        highlights = [
            {
                icon: "integration.png",
                title: "Multichannel Selling",
                text: "Shopify allows for selling not just through the web store but also across multiple channels like Facebook, Instagram, Amazon, and more. This allows businesses to reach their customers wherever they are.",
            },
            {
                icon: "user.png",
                title: "User-Friendly Platform",
                text: "Shopify is known for its easy-to-use interface, making it accessible to those without extensive technical skills. It allows anyone to set up an online store quickly and manage it with ease.",
            },
            {
                icon: "graphic.png",
                title: "Versatile Customization Options",
                text: "Shopify offers a wide range of themes and customization options to create a unique and professional online store. The platform supports extensive modification to make sure your site matches your brand. ",
            },
            {
                icon: "buy.png",
                title: "Integrated Payments and Shipping",
                text: "Shopify provides a seamless shopping experience with its built-in payment gateway, Shopify Payments, and integrated shipping options. This eliminates the need for third-party payment and shipping services.",
            },
        ];
    } else if (partnerName === "BigCommerce") {
        highlights = [
            {
                icon: "buy.png",
                title: "Open SaaS platform",
                text: "BigCommerce is an Open SaaS platform that allows for a lot of flexibility. Businesses can use its APIs to create custom integrations and tailor the platform to their specific needs.",
            },
            {
                icon: "scalability.png",
                title: "Scalability and Robustness",
                text: "BigCommerce offers an e-commerce platform built to scale and handle high volume traffic and sales, allowing businesses to grow without worrying about website crashes during peak times. Its robust architecture and unlimited API call volumes make it a powerful solution for businesses of all sizes.",
            },
            {
                icon: "marketing.png",
                title: "Built-in SEO and Marketing Tools",
                text: "BigCommerce includes built-in SEO features that make it easier for businesses to rank higher in search engine results. It also offers an array of marketing tools and integrations, from email marketing to social media advertising, helping businesses to attract and retain customers effectively.",
            },
            {
                icon: "integration.png",
                title: "Seamless Integration and Customization",
                text: "BigCommerce provides seamless integration with various third-party apps and services, such as payment gateways, shipping providers, and CRM systems. The platform also allows for extensive customization to create a unique and branded online store, with a variety of themes and an easy-to-use interface.",
            }
        ];
    }

    return (
        <div className="row w-100 text-center my-4">
            <div className="highlights-container d-flex flex-wrap col-lg-6 justify-content-around">
                {highlights.map((highlight, index) => (
                    <div className="highlight-item col-12 col-md-6 col-lg-6 d-flex flex-column justify-content-center align-items-center" key={index}>
                        <img src={`/assets/images/${highlight.icon}`} width={85} alt={highlight.title} />
                        <h5 className="py-1">{highlight.title}</h5>
                    </div>
                ))}
            </div>
            <div className="partner-item col-12 col-md-6 col-lg-6">
                <a href={`https://${partnerName.toLowerCase()}.com`} target="_blank" rel="noopener noreferrer">
                    <img src={`/assets/images/${partnerName.toLowerCase()}-banner.png`} width={'100%'} alt={partnerName} />
                </a>
            </div>
        </div>
    );
};

export default Banner;