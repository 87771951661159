import React from 'react';
import { Link } from 'react-router-dom';

const SubscribeSection = () => {
  return (
    <section>
      <div className='container'>
        <div className='bg-primary bg-opacity-10 rounded-box-1 p-4 p-lg-5 position-relative'>
          {/* bg line */}
          <span className='position-absolute bottom-0'>
            <img
              className='d-block h-100 w-100 opacity-25'
              src='/assets/images/bg-line-0.svg'
              alt='...'
            />
          </span>
          <span className='position-absolute end-0 top-0'>
            <img
              className='d-block h-100 w-100 opacity-25'
              src='/assets/images/bg-line-1.svg'
              alt='...'
            />
          </span>
          <span
            className='position-absolute'
            style={{ right: '-1rem', top: '-1rem' }}
          >
            <img
              className='d-block h-100 w-100'
              src='/assets/images/send-icon.png'
              alt='...'
            />
          </span>
          <span
            className='position-absolute'
            style={{ right: '-5%', bottom: '-2rem' }}
          >
            <img
              className='d-block h-100 w-100'
              src='/assets/images/bg-plus-1.png'
              alt='...'
            />
          </span>
          {/* /bg line */}
          <ul className='nav text-center flex-column gap-5 position-relative'>
            <li>
              <div className='px-0 mx-auto col-10 col-md-10 col-xl-8'>
                <div className='h2 mb-0 fw-bold font-volkhov text-secondary lh-base'>
                  Unlock Your Potential. Get in Touch with Us Now to Kickstart Your Journey!
                </div>
              </div>
            </li>
            <li>
              <div className='px-0 mx-auto col-12 col-md-12 col-lg-10 col-xl-8 col-xxl-7'>
                <form noValidate className='contact-us'>
                  <div className='d-block d-md-flex'>
                    <div>
                      <Link
                        to='/contact'
                        className='btn border-0 btn-lg bg-gradient-1 h-100 w-100 text-white'
                      >
                        Get Started
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default SubscribeSection;
