import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AboutPage from './pages/about/AboutPage';
import ContactPage from './pages/contact/ContactPage';
import HomePage from './pages/home/HomePage';
import ProjectsPage from './pages/projects/ProjectsPage';
import ServicesPage from './pages/our-services/ServicesPage';
import NotFoundPage from './pages/not-found/NotFoundPage';
import CareersPage from './pages/careers/CareersPage';
import ProjectTemplate from './pages/projects/template/ProjectTemplate';
import BigCommerce from './pages/partners/BigCommerce';
import Shopify from './pages/partners/Shopify';

const Paths = () => {
  const project = {
    slug: 'pakmor',
    title: 'PakMor LLC',
    logo: '/assets/images/pakmor.png',
    description: (
      <div style={{ whiteSpace: 'pre-wrap' }}>
        PakMor LLC is a manufacturing company specializing in auto parts and production and repair. The company started operating in Pakistan and has now expanded to the United States. With their new presence in the States, it was mandatory to create a website that would allow them to showcase their products and services to their customers. The website was built using React and Node.js. The website is hosted on Heroku, and the domain is registered with GoDaddy.
      </div>
    ),
    client: 'PakMor LLC',
    duration: '2 months',
    teamSize: 3,
    technologiesUsed: ['React', 'Node.js', 'Express', 'SendGrid', 'Heroku', 'GoDaddy'],
    challenges: (
      <div style={{ whiteSpace: 'pre-wrap' }}>
        The challenges faced during this project were mainly around translating that international expertise in a way that would be relevant to the US market. The client had a lot of experience in the industry, but it was important to emphasize the value of that experience to the US market. The other challenge was to create a website that would be easy to use and navigate for the client and their customers.
      </div>
    ),
    solution: (
      <div style={{ whiteSpace: 'pre-wrap' }}>
        As a solution to the previous challenges, we studied the competition and the industry in the US and created a website that highlighted their expertise in production as well as repair. We added sections to highlight how efficient their production line is and how experienced their team is in repairing auto parts. We also highlighted their key clients, who are world-renowned in the automotive industry.
        <br />
        <br />
        To further enhance the company's credibility and showcase their expertise, we encouraged the collection of customer testimonials and case studies. These testimonials were prominently displayed on the website and social media channels, highlighting the positive experiences of satisfied customers and reinforcing the company's reputation for exceptional service.
        <br />
        <br />
        In addition to the website, we developed a robust content marketing plan that involved creating engaging and informative blog posts, articles, and videos related to the automotive industry. By sharing valuable insights and expert advice, we aimed to position the company as a trusted authority in the field and attract potential customers seeking reliable production and repair services.
      </div>
    ),
    results: (
      <div style={{ whiteSpace: 'pre-wrap' }}>
        The results of this project were highly successful. The new website effectively showcased PakMor LLC's expertise in production and repair, attracting a significant increase in website traffic and generating valuable leads. The customer testimonials and case studies played a crucial role in building trust and credibility, further solidifying PakMor LLC's reputation for exceptional service.
        <br />
        <br />
        The content marketing efforts resulted in increased brand awareness and engagement. The blog posts, articles, and videos shared valuable industry insights, positioning PakMor LLC as a trusted resource for automotive production and repair information. This helped attract a targeted audience and establish the company as a thought leader in the field.
        <br />
        <br />
        Overall, the project successfully addressed the initial challenges and delivered tangible results for PakMor LLC. The website and content marketing efforts positioned the company for continued growth and success in the US market, establishing them as a top choice for auto parts manufacturing and repair services.
      </div>
    ),
  };  
  
  return (
    <Routes>
      <Route path='/' element={<HomePage />} />
      <Route path='/contact' element={<ContactPage />} />
      <Route path='/projects' element={<ProjectsPage />} />
      <Route path='/projects/:slug' element={<ProjectTemplate project={project} />} />
      <Route path='/about' element={<AboutPage />} />
      <Route path='/services' element={<ServicesPage />} />
      <Route path='/careers' element={<CareersPage />} />
      <Route path='*' element={<NotFoundPage />} />
      <Route path='/bigcommerce' element={<BigCommerce />} />
      <Route path='/shopify' element={<Shopify />} />
    </Routes>
  );
};

export default Paths;
