import React from 'react';
import { Link } from 'react-router-dom';

const ProjectsSection = () => {
  const list = [
    {
      isLeft: true,
      logoUrl: '/assets/images/pakmor.png',
      imageUrl: '/assets/images/mockup1.png',
      title: `Broader audience reach and higher number of quality leads`,
      text: `PakMor - Auto Parts Manufacturer and Distributor`,
    },
  ];

  return (
    <section className='about-section' id='about'>
      <div className='container position-relative'>
        <ul className='nav flex-column gap-5 position-relative'>
          {/* background */}
          <span className='position-absolute end-0 top-20 pt-5'>
            <img
              className='d-block h-100 w-100'
              src='/assets/images/bg-plus.png'
              alt='...'
            />
          </span>
          <span className='d-lg-none position-absolute top-0'>
            <img
              className='d-block h-100 w-100'
              src='/assets/images/bg-wave.png'
              alt='...'
            />
          </span>
          <span
            className='d-none d-lg-block position-absolute'
            style={{
              top: '24%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <img
              className='d-block h-100 w-100'
              src='/assets/images/bg-wave.png'
              alt='...'
            />
          </span>
          {/* /background */}
          {list.map((item) => (
            <>
              {item.isLeft ? (
                <li>
                  <div className='row align-items-center gy-4 position-relative'>
                    <div className='col col-lg-6 col-xxl-5'>
                      <div>
                        <div className='logo-2'>
                          <img src={`${item.logoUrl}`} alt='...' />
                        </div>
                        <div className='display-6 fw-bold font-volkhov text-secondary'>
                          {item.title}
                        </div>
                        <div className='py-1 rounded rounded-3 w-25 bg-primary my-3' />
                        <h5 className='mb-4 fw-normal lh-base text-dark text-opacity-75'>
                          {item.text}
                        </h5>
                        <div>
                          <Link to='/projects/pakmor-llc' className='btn btn-lg btn-primary'>
                            Read the full story
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className='col-12 col-lg-6 col-xxl-7 order-first order-lg-last'>
                      <div className='height-size-1'>
                        <img
                          className='d-block h-100 w-100 object-contain'
                          src={`${item.imageUrl}`}
                          alt='...'
                        />
                      </div>
                    </div>
                  </div>
                </li>
              ) : (
                <li>
                  {' '}
                  <div className='row align-items-center gy-4 position-relative'>
                    <div className='col-12 col-lg-6 col-xxl-7'>
                      <div className='height-size-1'>
                        <img
                          className='d-block h-100 w-100 object-contain'
                          src={`${item.imageUrl}`}
                          alt='...'
                        />
                      </div>
                    </div>
                    <div className='col col-lg-6 col-xxl-5'>
                      <div>
                        {/* <div className='logo-2'>
                          <img src={`${item.logoUrl}`} alt='...' />
                        </div> */}
                        <div className='display-5 fw-bold font-volkhov text-secondary'>
                          {item.title}
                        </div>
                        <div className='py-1 rounded rounded-3 w-25 bg-primary my-3' />
                        <h5 className='mb-4 fw-normal lh-base text-dark text-opacity-75'>
                          {item.text}
                        </h5>
                        {/* <div>
                          <Link to className='btn btn-lg btn-primary'>
                            Read the full story
                          </Link>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </li>
              )}
            </>
          ))}
        </ul>
        {/* <div className='mt-5 pt-5 text-center'>
          <Link to='/service' className='btn btn-lg btn-primary'>
            Read more success stories
          </Link>
        </div> */}
      </div>
    </section>
  );
};

export default ProjectsSection;
