import React from 'react';

const AboutYourProject = () => {
  const list = [
    {
      iconUrl: '/assets/images/book.svg',
      title: 'Choose Project Type',
      text: 'Discover the perfect project type that aligns with your goals and objectives. Our diverse range of options ensures a tailored approach to meet your unique needs.',
    },
    {
      iconUrl: '/assets/images/website.svg',
      title: 'Meetup for details',
      text: 'Engage in a comprehensive meetup where we delve into the specifics of your project. Through in-depth discussions and active collaboration, we gather crucial insights to lay a solid foundation for success.',
    },
    {
      iconUrl: '/assets/images/web.svg',
      title: 'Wait for Delivery',
      text: 'Experience peace of mind as our dedicated team brings your project to life. With meticulous attention to detail and a commitment to excellence, we work diligently behind the scenes to deliver exceptional results within the designated timeline.',
    },
  ];

  return (
    <section className='bg-white'>
      <div className='container'>
        <div className='row align-items-center w-100embry riddle gy-4 position-relative'>
          <div className='col col-lg-8 col-xxl-8 m-auto'>
            <div className='px-0 col-12 col-xl-9 col-xxl-12'>
              <h6 className='mb-3 fw-bold text-dark text-opacity-50'>
                Step Process
              </h6>
              <div className='mb-4 display-5 fw-bold font-volkhov text-dark text-capitalize'>
                tell us about your project
              </div>
              <ul className='nav flex-column gap-4'>
                {list.map((item) => (
                  <li>
                    <div className='d-flex'>
                      <div className='icon-size-medium me-3'>
                        <object
                          className='d-block h-100 w-100'
                          data={`${item.iconUrl}`}
                          type='image/svg+xml'
                          aria-label='icon'
                        />
                      </div>
                      <div>
                        <h6 className='mb-1 fw-bold text-dark text-opacity-50'>
                          {item.title}
                        </h6>
                        <p className='mb-0 text-dark small'>
                          {item.text}
                        </p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <br />
    </section>
  );
};

export default AboutYourProject;
