import React from 'react';
import { Link } from 'react-router-dom';
import BgPattern from '../pattern/BgPattern';
import SocialMediaSection from '../SocialMediaSection';

const FooterPrimary = () => {
  // const location = useLocation();

  // const hideFooterPrimary = location.pathname === '/project';

  // if (hideFooterPrimary) {
  //   return null;
  // }

  const currentYear = new Date().getFullYear();

  const serviceList = [
    { name: 'Web development' },
    { name: 'Mobile development' },
    { name: 'Ecommerce development' },
    { name: 'Product design' },
  ];

  // const projectsList = [
  //   { name: 'Project' },
  // ];

  // const companyList = [
  //   { name: 'About' },
  // ];

  return (
    <footer className='footer-container container-xxl px-0'>
      <div className='bg-white py-5 position-relative'>
        <BgPattern />
        <div className='container'>
          <div>
            <img className='footer-logo' src='/assets/images/logo1.png' alt='Menara Solutions' />
            <div>
              <ul className='nav row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-5 gy-4 small'>
                <li>
                  <ul className='nav flex-column gap-2'>
                    <li>
                      <Link to={'/services'}><h5 className='mb-2 fw-bold'>Services</h5></Link>
                    </li>
                    {serviceList.map((item) => (
                      <li>
                        {item.name}
                      </li>
                    ))}
                  </ul>
                </li>
                {/* <li>
                  <ul className='nav flex-column gap-2'>
                    <li>
                      <h5 className='mb-2 fw-bold'>Projects</h5>
                    </li>
                    {projectsList.map((item) => (
                      <li>
                        <Link to>{item.name}</Link>
                      </li>
                    ))}
                  </ul>
                </li> */}
                {/* <li>
                  <ul className='nav flex-column gap-2'>
                    <li>
                      <h5 className='mb-2 fw-bold'>Company</h5>
                    </li>
                    {companyList.map((item) => (
                      <li>
                        <Link to>{item.name}</Link>
                      </li>
                    ))}
                  </ul>
                </li> */}
                <li>
                  <ul className='nav flex-column gap-2'>
                    <li>
                      <h5 className='mb-2 fw-bold'>Careers</h5>
                    </li>
                    <li>
                      <Link to={'/careers'}>Current openings</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <ul className='nav flex-column gap-2'>
                    <li>
                      <Link to={'/contact'}><h5 className='mb-2 fw-bold'>Contact</h5></Link>
                    </li>
                    <li>
                      <address className='text-black-50'>
                        Menara Solutions
                        <br />
                        Atlanta, GA 30339
                      </address>
                    </li>
                    <li>
                      <a href='tel:4049106051' className='text-black fw-bold'>
                        (404) 910-6051 (US)
                      </a>
                    </li>
                    <li>
                      <a
                        href='mailto: contact@menarasolutions.com'
                        className='text-primary fw-bold'
                      >
                        contact@menarasolutions.com
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <ul className='nav flex-column gap-2'>
                    <li>
                      <h5 className='mb-2 fw-bold'>Follow us</h5>
                    </li>
                    <SocialMediaSection />
                  </ul>
                </li>
                <li>
                  <div className='nav flex-row gap-2 partner-tiles-container'>
                    <img className='partner-tiles object-scale' src='/assets/images/BC-Partner-Tile.png' alt='BigCommerce Certified Partnership' />
                    <img className='partner-tiles object-scale' src='/assets/images/Shopify-Tile.png' alt='Shopify Certified Partnership' />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className='bg-white text-dark text-opacity-75 py-4 border-top shadow-sm text-center'>
        <span>Copyright © {currentYear} Menara Solutions.</span>
      </div>
    </footer>
  );
};

export default FooterPrimary;
