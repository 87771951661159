import React from 'react';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Thumbs } from 'swiper';

const OurTeamSection = () => {
  // const [thumbsSwiper, setThumbsSwiper] = React.useState(null);

  // const avatarList = [
  //   {
  //     isSmall: true,
  //     imageUrl:
  //       'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
  //   },
  //   {
  //     isMid: true,
  //     imageUrl:
  //       'https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80',
  //   },
  //   { imageUrl: '/assets/images/avatar-0.png' },
  //   {
  //     isMid: true,
  //     imageUrl:
  //       'https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80',
  //   },
  //   {
  //     isSmall: true,
  //     imageUrl:
  //       'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
  //   },
  // ];

  return (
    <section className='py-0 mt-5'>
      <div className='container-xxl px-0'>
        <div className='row row-cols-1 row-cols-lg-2 mx-0'>
          <div className='px-0'>
            <div className='bg-primary px-3 py-5 height-size-1  d-flex flex-column justify-content-center'>
              <div className='col-8 col-md-9 col-lg-8 col-xl-7 col-xxl-6 px-0 mx-auto'>
                <div className='mb-4 position-relative icon-size'>
                  <img
                    className='d-block h-100 w-100'
                    src='/assets/images/Quote.png'
                    alt='..'
                  />
                  <span
                    style={{ left: '-15%' }}
                    className='position-absolute top-20 pt-4'
                  >
                    <img
                      className='d-block h-100 w-100'
                      src='/assets/images/bg-dots.png'
                      alt='...'
                    />
                  </span>
                </div>
                <div className='display-5 fw-bold font-volkhov text-white'>
                  See what our clients say about us
                </div>
              </div>
            </div>
          </div>
          <div className='px-0 mt-4 mt-lg-0'>
            <div className='px-3 py-5 text-center'>
              <div className='col-12 col-xl-8 col-xxl-7 px-0 mx-auto'>
                <h2 className='mb-4 text-secondary font-volkhov fw-bold'>
                  PakMor LLC
                </h2>
                <p className='mb-4 lh-base'>
                From the initial consultation to the final product, Menara Solutions' expertise and unwavering commitment 
                to our goals were evident throughout the process. They skillfully crafted a visually captivating and 
                user-friendly website that effectively showcases our extensive range of products and services, 
                enabling us to reach a broader audience and generate a higher number of quality leads. We wholeheartedly 
                 recommend their services to any business seeking a top-tier web development partner.
                </p>

                <div
                  className='mb-4 icon-size-large mx-auto d-flex align-items-center justify-content-center'
                >
                  <img
                    className='d-block h-100 w-100'
                    src='/assets/images/pakmor.png'
                    alt='...'
                  />
                </div>

                {/* <div>
                  <Swiper
                    onSwiper={setThumbsSwiper}
                    spaceBetween={10}
                    slidesPerView={5}
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[Thumbs]}
                    className='mySwiper'
                  >
                    {avatarList.map((item, i) => (
                      <SwiperSlide key={i}>
                        <button
                          type='button'
                          className='btn p-0 icon-size mx-auto d-flex align-items-center justify-content-center'
                        >
                          <div
                            className={`bg-light rounded-circle ${
                              (item.isSmall && 'h-50 w-50') ||
                              (item.isMid && 'h-75 w-75')
                            }`}
                          >
                            <img
                              className='d-block h-100 w-100 rounded-circle object-cover'
                              src={`${item.imageUrl}`}
                              alt='...'
                            />
                          </div>
                        </button>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div> */}

                {/* <div className='mt-4 text-center'>
                  <Swiper
                    loop={true}
                    spaceBetween={10}
                    thumbs={{ swiper: thumbsSwiper }}
                    modules={[Thumbs]}
                  >
                    {avatarList.map((item, i) => (
                      <SwiperSlide key={i}>
                        <div className='small'>
                          <div className='fw-bold text-secondary text-capitalize'>
                            Samantha William {i}
                          </div>
                          <span className='text-black-50'>
                            CTO & Founder of Ffriwise
                          </span>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurTeamSection;
