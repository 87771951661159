import React from 'react';
import BgPattern from '../../../components/pattern/BgPattern';

const PartnerHero = (partner) => {
    const partnerName = partner.partner;

    return (
        <section className='partner-hero position-relative container-xxl px-0' id='hero'>
            <BgPattern />
            <div className='container position-relative'>
                <div className='px-0 mx-auto col-12 col-md-11 col-lg-10 col-xl-9 text-center'>
                    <ul className='nav flex-column gap-5'>
                        {partnerName === 'Shopify' ? (
                            <li>
                                <div className='display-6 fw-bold font-volkhov text-secondary'>
                                    <span className='text-line-2'>{partnerName}</span>: Empowering Entrepreneurs Worldwide
                                </div>
                            </li>
                        ) : (
                            <li>
                                <div className='display-6 fw-bold font-volkhov text-secondary'>
                                    <span className='text-line-2'>{partnerName}</span>: Where E-commerce Dreams Come True
                                </div>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default PartnerHero;
