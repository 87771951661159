import React from 'react';
import { Link } from 'react-router-dom';
import ServiceCard from '../../../components/cards/ServiceCard';

const ServiceSection = () => {
  const list = [
    {
      imageUrl: '/assets/images/MVP Development.png',
      titleOne: 'E-Commerce',
      titleTwo: 'Development',
      text: `Empowering online businesses with tailored E-Commerce solutions, ensuring swift launch and scalability. Unlock your business's full potential while we handle digital storefront development.`,
    },
    {
      imageUrl: '/assets/images/Web Development.png',
      titleOne: 'Web',
      titleTwo: 'Development',
      text: `High-performance web applications tailored to your business needs. Expert developers proficient in Node.js, Ruby on Rails, Vue.js, React, AngularJS, delivering robust backends, seamless frontends, and full-stack solutions to drive your business forward.`,
    },
    {
      imageUrl: '/assets/images/Mobile Development.png',
      titleOne: 'Mobile',
      titleTwo: 'Development',
      text: `Reach both Android and iOS users simultaneously with our hybrid mobile app development using React Native or Ionic. Our talented developers ensure a smooth launch with seamless backend integration, helping you captivate a wider audience in the mobile market.`,
    },
  ];

  return (
    <section>
      <div className='container position-relative'>
        {/* <span className='position-absolute end-0 bottom-50'>
          <img
            className='d-block h-100 w-100'
            src='/assets/images/bg-plus.png'
            alt='...'
          />
        </span> */}
        <div className='position-relative'>
          <div>
            <ul className='nav row row-cols-1 row-cols-sm-2 row-cols-lg-3 gy-4 justify-content-center'>
              {list.map((item) => (
                <li className='service-cards-list'>
                  <ServiceCard item={item} />
                </li>
              ))}
            </ul>
          </div>
          <div className='mt-5 text-center'>
            <Link to='/services' className='btn btn-lg btn-primary'>
              View All Services
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceSection;
