import React from 'react';
// import { Link } from 'react-router-dom';

const ServiceSmallCard = ({ item }) => {
  return (
    <div
      className={`service-card p-4 d-flex flex-column h-100 ${item.isActive && 'active'
        }`}
    >
      <div className='mb-4 icon-size-1'>
        <img
          className='d-block h-100 w-100'
          src={`${item.imageUrl}`}
          alt='...'
        />
      </div>
      <h5 className='mb-2 fw-500 lh-base text-capitalize text-secondary'>
        {item.titleOne} {item.titleTwo}
      </h5>
      <p className='mb-4 text-black-50 lh-lg small'>{item.text}</p>
      {item.icons && (
        <div className='mt-auto'>
          <h5 className='mb-2 fw-500 lh-base text-capitalize text-secondary'>
            Partners:
          </h5>
          <div className='nav flex-row gap-2'>
            {item.icons.map((icon) => (
              <a href={`/${icon.name.toLowerCase()}`} className='services-icons' ><img src={`/assets/images/${icon.name}.png`} alt={icon.name} /></a>
            ))}
          </div>
        </div>
      )}
      {/* <div className='mt-auto'>
        <Link
          to
          className='icon-size-1 btn-light text-secondary rounded-circle d-flex align-items-center justify-content-center'
        >
          <i className='fa fa-arrow-right small' />
        </Link>
      </div> */}
    </div>
  );
};

export default ServiceSmallCard;
