import React from 'react';
import { Link } from 'react-router-dom';

const ProjectCard = (item) => {
  console.log(item);

  return (
    <div
      className='bg-white rounded-lg shadow border px-4 d-flex flex-column flex-fill'
      style={{ marginTop: '7.5rem' }}
    >
      <div className='height-size-2 rounded-lg border shadow-sm mt-n-6rem'>
        <img
          className='d-block h-100 w-100 object-cover rounded-lg border shadow-sm'
          src={`${item.item.imageUrl}`}
          alt={item.item.title}
        />
      </div>
      <div className='py-3 position-relative flex-fill'>
        <ul className='nav flex-column gap-2'>
          <li>
            <b className='small text-primary text-capitalize'>{item.item.title}</b>
          </li>
          <li>
            <h5 className='mb-0'>
              {item.item.text}
            </h5>
          </li>
        </ul>
        <div className='mt-5' />
        <div
          className='position-absolute w-100 text-center'
          style={{ bottom: '-13%' }}
        >
          <Link to={item.item.projectUrl} className='btn btn-lg btn-primary'>
            View the case
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
