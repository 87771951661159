import mapboxgl from 'mapbox-gl';
import { useEffect, useRef } from 'react';

mapboxgl.accessToken = process.env.REACT_APP_MAP_ACCESS_TOKEN;

export default function Map() {
    const mapContainer = useRef(null);
    const map = useRef(null);

    useEffect(() => {
        if (map.current) return;
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/hzemzem/cl0pp6mfq000514p4cf3ozqtm',
            center: [-84.386330, 33.753746],
            zoom: 10
        });

        map.current.scrollZoom.disable();

        map.current.dragRotate.disable();

        return () => map.current.remove();
    }, []);

    return (
        <div>
            <div ref={mapContainer} className="map-container" />
        </div>
    );
}