import React from 'react';
import PartnerHero from './components/PartnerHero';
import Banner from './components/Banner';
import { Plus } from 'react-feather';
import SubscribeSection from '../../pages/our-services/section/SubscribeSection';
import { Divider } from 'antd';

const Shopify = () => {

    return (
        <>
            <PartnerHero partner="Shopify" />
            <div className='container ms-partnership'>
                <div className='row'>
                    <div className='ms-partnership-img'>
                        <img id='partnership-img' className='inline-block col-md-2 col-12' src='/assets/images/shopify.png' alt='Shopify' />
                        <Plus />
                        <img id='partnership-img' className='inline-block col-md-2 col-12' src='/assets/images/logo1.png' alt='Menara Solutions' />
                    </div>
                    <p>
                        Shopify is a top ecommerce platform, offering a SaaS solution for businesses to build and manage online stores. It provides product management, customizable designs, marketing tools, and integrations. As a fully hosted platform, Shopify handles technical aspects like hosting and security, allowing businesses to focus on their products and customers. As of September 2021, it was a major player in the ecommerce market.
                    </p>
                </div>
            </div>
            {/* <div className='container'>
                <Divider />
            </div> */}
            <div className='partner-sections'>
                <Banner partner="Shopify" />
                <Divider />
                <section className='service-card p-5'>
                    <h2>Mobile Commerce</h2>
                    <p>With Shopify, your store is fully optimized for mobile devices. Shoppers can browse and make purchases on-the-go, providing a seamless mobile shopping experience.</p>
                </section>

                <section className='service-card p-5'>
                    <h2>Flexible Design Options</h2>
                    <p>Shopify offers a variety of professional and customizable themes, allowing you to create a unique and visually appealing online store that reflects your brand.</p>
                </section>

                <section className='service-card p-5'>
                    <h2>Secure and Reliable</h2>
                    <p>Shopify takes security seriously. Your store is hosted on Shopify's secure servers, and they handle all security upgrades, backups, and maintenance, so you can focus on your business.</p>
                </section>

                <section className='service-card p-5'>
                    <h2>Scalable and Reliable Infrastructure</h2>
                    <p>Shopify is built on a highly scalable infrastructure, ensuring that your store can handle high traffic volumes and sudden spikes in demand without any performance issues.</p>
                </section>
            </div>
            <SubscribeSection />
        </>
    );
};

export default Shopify;
