import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

const SocialMediaSection = () => {
  const list = [
    { imageUrl: 'github', linkUrl: 'https://github.com/MenaraSol' },
    { imageUrl: 'linkedin', linkUrl: 'https://www.linkedin.com/company/menarasolutions/' },
    { imageUrl: 'instagram', linkUrl: 'https://instagram.com/menarasolutions' },
  ];

  return (
    <div className='py-0 bg-white social-media-section'>
      <div className='container py-xl-0'>
        <div>
          <Swiper
            breakpoints={{
              '@0.00': {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              '@0.75': {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              '@1.00': {
                slidesPerView: 3,
                spaceBetween: 20,
                width: 200,
              },
              '@1.50': {
                slidesPerView: 3,
                spaceBetween: 20,
                width: 400,
              },
            }}
          >
            {list.map((item, i) => (
              <SwiperSlide key={i} className="social-media">
                <a
                  className='d-block'
                  href={item.linkUrl}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img
                    className='d-block h-100 w-100 object-scale'
                    src={`/assets/images/${item.imageUrl}.svg`}
                    alt='...'
                  />
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default SocialMediaSection;
